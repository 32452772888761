import React, { useRef } from 'react';

import Container from '../components/Container';
import Hero from '../components/Hero';
import ThemeLink from '../components/ThemeLink';
import Layout from '../components/Layout/Layout';

import * as styles from './about.module.css';
const AboutPage = (props) => {
  let historyRef = useRef();
  let valuesRef = useRef();
  let sustainabilityRef = useRef();

  const handleScroll = (elementReference) => {
    if (elementReference) {
      window.scrollTo({
        behavior: 'smooth',
        top: elementReference.current.offsetTop - 280,
      });
    }
  };

  return (
    <Layout disablePaddingBottom>
      <div className={styles.root}>
        {/* Hero Container */}
        <Hero
          maxWidth={'900px'}
          image={'/about.jpg'}
          title={`Anjuans\nIndian brand since 2020`}
        />

        <div className={styles.navContainer}>
          <ThemeLink onClick={() => handleScroll(historyRef)} to={'#history'}>
            History
          </ThemeLink>
          <ThemeLink onClick={() => handleScroll(valuesRef)} to={'#values'}>
            Values
          </ThemeLink>
          <ThemeLink
            onClick={() => handleScroll(sustainabilityRef)}
            to={'#sustainability'}
          >
            Sustainability
          </ThemeLink>
        </div>

        <Container size={'large'} spacing={'min'}>
          <div className={styles.detailContainer} ref={historyRef}>
            <p>
              We started in 2020 with a simple goal - to make India's food
              tastier and healthier. We're the folks behind homemade spices and
              nutritious health mix powders.
            </p>
            <br />
            <br />
            <p>
              We've worked hard to create some of the tastiest spice blends out
              there. And guess what? Everything is made right here in India, in
              our own little kitchen. We've been doing this since 2020, and
              we're here to make your meals better.
            </p>
          </div>
        </Container>

        <div className={styles.imageContainer}>
          <img alt={'values'} src={'/health-mix-powder.webp'}></img>
        </div>

        <Container size={'large'} spacing={'min'}>
          <div className={styles.content}>
            <h3>Our Values</h3>
            <div ref={valuesRef}>
              <p>
                At Anujans, our values are the heart of everything we do. We are
                deeply committed to delivering the highest quality spices and
                health mix powders to our customers. Quality is not just a goal;
                it's our unwavering standard. Beyond that, we believe in the
                power of community and looking after one another. This sense of
                togetherness extends within our organization and beyond,
                creating a strong foundation for our mission. Our tagline,
                'Healthy family. Healthy nation,' reflects our belief that by
                upholding these values, we contribute to building a healthier
                and stronger nation. Welcome to the world of Anujans, where
                quality and community matter most.
              </p>
              <ol>
                <li>Quality is our unwavering standard.</li>
                <li>Building strong bonds for a healthier tomorrow.</li>
                <li>Promoting health and well-being.</li>
              </ol>
              {/* <img alt={'founder'} src={'/about2.png'}></img> */}
            </div>
            <h3>Sustainability</h3>
            <div id={'#sustainability'} ref={sustainabilityRef}>
              <p>
                Sustainability is at the core of Anujans' mission. We understand
                the vital importance of preserving our environment and
                supporting the well-being of future generations. That's why we
                are committed to sustainable practices throughout our production
                process. From sourcing ingredients responsibly to minimizing
                waste and energy consumption, every step we take is guided by
                our dedication to environmental stewardship. By choosing
                Anujans, you're not only choosing the finest natural spices and
                health mix powders but also supporting a brand that cares deeply
                about the planet we all call home.
              </p>
              <p>
                Our commitment to sustainability extends beyond our products. We
                actively engage in community initiatives that promote
                eco-consciousness and strive to be a positive force for change.
                Anujans believes that by fostering a sense of responsibility
                towards the environment, we can contribute to building a
                healthier and more sustainable world. Join us in our journey
                towards a greener future, one delicious spice blend and health
                mix at a time. Because at Anujans, sustainability isn't just a
                choice; it's a way of life.
              </p>
            </div>
          </div>
        </Container>

        {/* <div className={styles.imageContainer}>
          <img alt={'shirt backwards'} src={'/about3.png'}></img>
        </div> */}
      </div>
    </Layout>
  );
};

export default AboutPage;
